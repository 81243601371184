export default [{
  header: 'Dashboards',
  icon: 'HomeIcon',
  children: [{
    title: 'Dashboards',
    route: 'dashboard',
    icon: 'ShoppingCartIcon',
  },

  ],
}]
